<template>
  <div>
    <el-tabs type="card" v-model="activeTab" @tab-click="handleClick">
      <el-tab-pane label="Fees" name="fees">
        <Products
          :regiProduct="regiProduct"
          :nationalRegoProduct="nationalRegoProduct"
          :stateRegoProduct="stateRegoProduct"
          :regionRegoProduct="regionRegoProduct"
          :assocRegoProduct="assocRegoProduct"
          :clubRegoProduct="clubRegoProduct"
        />
      </el-tab-pane>
      <el-tab-pane label="Additional Questions" name="additional-questions" v-if="isVisibile">
        <AdditionalQuestions
          :regiProduct="regiProduct"
          :nationalRegoAQ="nationalRegoAQ"
          :stateRegoAQ="stateRegoAQ"
          :regionRegoAQ="regionRegoAQ"
          :assocRegoAQ="assocRegoAQ"
          :clubRegoAQ="clubRegoAQ"
        />
      </el-tab-pane>
      <el-tab-pane name="saleable-items" label="Saleable Items">
        <SaleableItems
          :regiProduct="regiProduct"
          :nationalRegoSaleItems="nationalRegoSaleItems"
          :stateRegoSaleItems="stateRegoSaleItems"
          :regionRegoSaleItems="regionRegoSaleItems"
          :assocRegoSaleItems="assocRegoSaleItems"
          :clubRegoSaleItems="clubRegoSaleItems"
        />
      </el-tab-pane>
      <el-tab-pane label="Date Range Discounts" name="date-range-discounts">
        <DiscountsDateRange />
      </el-tab-pane>

      <el-tab-pane
        label="Discount Codes"
        name="discount-codes"
        v-if="
          (clearance === roles.clubAdmin && nationalId === 32) ||
          (clearance === roles.associationAdmin && nationalId === 31) ||
          (clearance === roles.clubAdmin && nationalId === 31)
        "
      >
        <DiscountsCode />
      </el-tab-pane>
      <el-tab-pane
        label="Family Discounts"
        name="family-discounts"
        v-if="clearance === roles.clubAdmin"
      >
        <DiscountsVolume :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane label="Competition Fees" name="competition-fees" v-if="showCompTabs">
        <CompetitionProducts />
      </el-tab-pane>
      <el-tab-pane
        label="Competition Additional Questions"
        name="competition-additional-questions"
        v-if="showCompTabs"
      >
        <CompetitionAdditionalQuestions />
      </el-tab-pane>
      <el-tab-pane
        label="Competition Saleable Items"
        name="competition-saleable-items"
        v-if="showCompTabs"
      >
        <CompetitionSaleableItems />
      </el-tab-pane>
      <el-tab-pane
        label="Competition Date Range Discounts"
        name="competition-date-range-discounts"
        v-if="showCompTabs"
      >
        <CompetitionDiscountsDateRange />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import Products from "./Products.vue";
import SaleableItems from "./SaleableItems.vue";
import DiscountsVolume from "./DiscountsVolume.vue";
import DiscountsCode from "./DiscountsCode.vue";
import DiscountsDateRange from "./DiscountsDateRange.vue";
import CompetitionProducts from "./CompetitionProducts.vue";
import CompetitionSaleableItems from "./CompetitionSaleableItems.vue";
import CompetitionDiscountsDateRange from "./CompetitionDiscountsDateRange.vue";
import AdditionalQuestions from "./AdditionalQuestions.vue";
import CompetitionAdditionalQuestions from "./CompetitionAdditionalQuestions.vue";
import { roles, nationals, memberTypes } from "../../utils/constants";
import filter from "lodash/fp/filter";
import contains from "lodash/fp/contains";

export default {
  name: "RegistrationProduct",
  props: ["tab"],
  components: {
    Products,
    DiscountsDateRange,
    DiscountsCode,
    DiscountsVolume,
    SaleableItems,
    CompetitionProducts,
    CompetitionSaleableItems,
    CompetitionDiscountsDateRange,
    AdditionalQuestions,
    CompetitionAdditionalQuestions,
  },
  data() {
    return {
      nationalRegoAQ: [],
      stateRegoAQ: [],
      regionRegoAQ: [],
      assocRegoAQ: [],
      clubRegoAQ: [],
      nationalRegoProduct: [],
      stateRegoProduct: [],
      regionRegoProduct: [],
      assocRegoProduct: [],
      clubRegoProduct: [],
      clubRegoSaleItems: [],
      assocRegoSaleItems: [],
      regionRegoSaleItems: [],
      stateRegoSaleItems: [],
      nationalRegoSaleItems: [],
      registerProduct: {},
      activeTab: "fees",
      regiProduct: {
        _id: "",
        entity_type: "",
        entity_id: -1,
        products: [],
        discounts: [],
        familyDiscounts: [],
        saleableitems: [],
        competitionProducts: [],
        competitionSaleableItems: [],
        competitionDiscounts: [],
        additionalQuestions: [],
        competitionAdditionalQuestions: [],
      },
      regiProductProps: {
        _id: "",
        entity_type: "",
        entity_id: -1,
        products: [],
        discounts: [],
        familyDiscounts: [],
        saleableitems: [],
        competitionProducts: [],
        competitionSaleableItems: [],
        competitionDiscounts: [],
        additionalQuestions: [],
        competitionAdditionalQuestions: [],
      },
      roles,
      allroles: memberTypes,
    };
  },
  computed: {
    isVisibile() {
      if (this.$store.getters["user/activeRole"].national_id === 31) return true;
      else if (
        this.$store.getters["user/activeRole"].national_id === 32 &&
        this.$store.getters["user/activeRole"].type === 1
      )
        return true;
      else return false;
    },
    nationalId() {
      return this.$store.getters["user/activeRole"].national_id;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    showCompTabs() {
      // Show comp product, saleable items and discounts tabs
      const activeRole = this.$store.getters["user/activeRole"];
      return activeRole.national_id === 31 && activeRole.type === roles.associationAdmin;
    },
  },
  mounted() {
    const activeTab = this.tab;
    if (activeTab) this.activeTab = activeTab;
    this.$store.commit("root/loading", true);
    const { type, entity, national_id } = this.$store.getters["user/activeRole"];
    const { _id } = entity;

    if (type === roles.clubAdmin) {
      this.$http
        .get(`/nrl/api/v1/admin/clubs/${_id}`)
        .then((clubRes) => {
          const clubData = clubRes.data.data;

          if (clubData.memberTypes) {
            this.availableRoles = filter((role) => contains(role.type)(clubData.memberTypes))(
              this.allroles
            );

            this.$http
              .get(`/nrl/api/v1/admin/registration-products/club/${_id}`)
              .then((response) => {
                if (response) {
                  const productList =
                    response.data.data && response.data.data.length > 0 ? response.data.data : null;

                  productList.forEach((product) => {
                    if (product) {
                      const {
                        _id,
                        entityType: entity_type,
                        entityId: entity_id,
                        products,
                        discounts,
                        familyDiscounts,
                        saleableItems,
                        additionalQuestions,
                        competitionProducts,
                        competitionSaleableItems,
                        competitionDiscounts,
                        competitionAdditionalQuestions,
                      } = product;

                      if (entity_type === "") return;

                      this.regiProduct = {
                        _id,
                        entity_type,
                        entity_id,
                        products: products.map((p) => ({
                          _id: p._id,
                          role: p.memberType,
                          gender: p.gender,
                          agefrom: p.ageFrom,
                          ageto: p.ageTo,
                          datefrom: p.dateFrom,
                          dateto: p.dateTo,
                          price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
                          price_int: p.price,
                          status: p.isRegoOpen,
                          payment: p.onlinePaymentRequired,
                        })),
                        additionalQuestions: additionalQuestions
                          ? additionalQuestions.map((p) => ({
                              _id: p._id,
                              type: p.type,
                              fieldName: p.fieldName,
                              question: p.question,
                              ageFrom: p.ageFrom,
                              ageTo: p.ageTo,
                              dateFrom: p.dateFrom,
                              dateTo: p.dateTo,
                              memberType: p.memberType,
                              gender: p.gender,
                              required: p.required,
                              isActive: p.isActive,
                              meta: {
                                options: _.get(p, "meta.options", []),
                                entityIds: _.get(p, "meta.entityIds", []),
                                entityType: _.get(p, "meta.entityType", undefined),
                              },
                            }))
                          : [],
                        discounts: discounts
                          ? discounts.map((p) => ({
                              _id: p._id,
                              type: p.type === "dollar" ? "$" : "%",
                              discountName: p.name,
                              discountFrom: p.fromDate,
                              discountTo: p.toDate,
                              discountAmount:
                                p.type === "dollar"
                                  ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                                  : p.amount.toString(),
                              discountAmount_int: p.amount,
                              discountMemberType: p.memberType,
                            }))
                          : [],
                        familyDiscounts: familyDiscounts
                          ? familyDiscounts.map((p) => ({
                              _id: p._id,
                              familyFrom: p.familyFrom,
                              familyTo: p.familyTo,
                              discountAmount: (parseFloat(p.amount) / 100).toFixed(2).toString(),
                              discountAmount_int: p.amount,
                              discountMemberType: p.memberType,
                            }))
                          : [],
                        saleableitems: saleableItems
                          ? saleableItems.map((p) => ({
                              _id: p._id,
                              name: p.name,
                              description: p.description,
                              types: p.memberTypes,
                              price_int: p.price,
                              price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                              requireSizes: p.requireSizes || false,
                              mandatory: p.mandatory || false,
                              seasonPass: p.seasonPass || false,
                              seasonPassFrom: p.seasonPassFrom,
                              seasonPassTo: p.seasonPassTo,
                              seasonPassExComps: p.seasonPassExComps,
                              gender: p.gender || "",
                              ageFrom: p.ageFrom,
                              ageTo: p.ageTo,
                              dateFrom: p.dateFrom,
                              dateTo: p.dateTo,
                              url: p.url,
                              meta: {
                                options: _.get(p, "meta.options", []),
                                entityIds: _.get(p, "meta.entityIds", []),
                                entityType: _.get(p, "meta.entityType", undefined),
                              },
                            }))
                          : [],
                        competitionProducts: competitionProducts
                          ? competitionProducts.map((p) => ({
                              _id: p._id,
                              competitionId: p.competitionId,
                              type: p.type,
                              price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
                              price_int: p.price,
                              status: p.isRegoOpen,
                              payment: p.onlinePaymentRequired,
                            }))
                          : [],
                        competitionAdditionalQuestions: competitionAdditionalQuestions
                          ? competitionAdditionalQuestions.map((p) => ({
                              _id: p._id,
                              type: p.type,
                              fieldName: p.fieldName,
                              question: p.question,
                              competitionId: p.competitionId,
                              required: p.required,
                              isActive: p.isActive,
                              meta: {
                                options: _.get(p, "meta.options", []),
                              },
                            }))
                          : [],
                        competitionSaleableItems: competitionSaleableItems
                          ? competitionSaleableItems.map((p) => ({
                              _id: p._id,
                              competitionId: p.competitionId,
                              name: p.name,
                              description: p.description,
                              price_int: p.price,
                              price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                              requireSizes: p.requireSizes || false,
                              mandatory: p.mandatory || false,
                              seasonPass: p.seasonPass || false,
                            }))
                          : [],
                        competitionDiscounts: competitionDiscounts
                          ? competitionDiscounts.map((p) => ({
                              _id: p._id,
                              competitionId: p.competitionId,
                              type: p.type === "dollar" ? "$" : "%",
                              discountName: p.name,
                              discountFrom: p.fromDate,
                              discountTo: p.toDate,
                              discountAmount:
                                p.type === "dollar"
                                  ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                                  : p.amount.toString(),
                              discountAmount_int: p.amount,
                            }))
                          : [],
                      };

                      this.optionsdisabled = new Array(this.regiProduct.products.length).fill(
                        false
                      );
                      this.discountoptionsdisabled = new Array(
                        this.regiProduct.discounts.length
                      ).fill(false);
                      this.familydiscountoptionsdisabled = new Array(
                        this.regiProduct.familyDiscounts.length
                      ).fill(false);
                      this.saleableoptionsdisabled = new Array(
                        this.regiProduct.saleableitems.length
                      ).fill(false);
                    }

                    let regPro = [];

                    if (this.regiProduct.entity_type === "state") {
                      regPro = this.regiProduct.products.filter(
                        (p) =>
                          this.availableRoles.some((r) => r.type === p.role) && p.price_int > 0.01
                      );

                      this.stateRegoProduct = regPro;
                      this.stateRegoSaleItems = this.regiProduct.saleableitems;
                      this.stateRegoAQ = this.regiProduct.additionalQuestions;
                    } else if (this.regiProduct.entity_type === "association") {
                      regPro = this.regiProduct.products.filter(
                        (p) =>
                          this.availableRoles.some((r) => r.type === p.role) && p.price_int > 0.01
                      );

                      this.assocRegoProduct = regPro;
                      this.assocRegoSaleItems = this.regiProduct.saleableitems;
                      this.assocRegoAQ = this.regiProduct.additionalQuestions;
                    } else if (this.regiProduct.entity_type === "region") {
                      regPro = this.regiProduct.products.filter(
                        (p) =>
                          this.availableRoles.some((r) => r.type === p.role) && p.price_int > 0.01
                      );

                      this.regionRegoProduct = regPro;
                      this.regionRegoSaleItems = this.regiProduct.saleableitems;
                      this.regionRegoAQ = this.regiProduct.additionalQuestions;
                    } else if (this.regiProduct.entity_type === "national") {
                      regPro = this.regiProduct.products.filter(
                        (p) =>
                          this.availableRoles.some((r) => r.type === p.role) && p.price_int > 0.01
                      );

                      this.nationalRegoProduct = regPro;
                      this.nationalRegoSaleItems = this.regiProduct.saleableitems;
                      const matchedEntityIdAQ = this.regiProduct.additionalQuestions.filter((aq) =>
                        aq.meta.entityIds.some((entity) => entity === _id)
                      );

                      const emptyEntityIdAQ = this.regiProduct.additionalQuestions.filter(
                        (aq) => aq.meta.entityIds.length === 0
                      );
                      this.nationalRegoAQ = [...matchedEntityIdAQ, ...emptyEntityIdAQ];
                    } else if (this.regiProduct.entity_type === "club") {
                      regPro = this.regiProduct.products.filter((p) =>
                        this.availableRoles.some((r) => r.type === p.role)
                      );
                      this.clubRegoProduct = regPro;
                      this.clubRegoSaleItems = this.regiProduct.saleableitems;
                      this.clubRegoAQ = this.regiProduct.additionalQuestions;
                    }
                  });
                }
              })
              .catch((e) => {
                this.$customError(e);
              });
          }
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    } else if (national_id === nationals.TFA) {
      this.availableRoles = this.allroles.filter((r) => r.tfa === true);
    } else {
      this.availableRoles = this.allroles;
    }
    if (type === roles.associationAdmin) {
      this.$http
        .get(`/nrl/api/v1/admin/registration-products/association/${_id}`)
        .then((response) => {
          const productList =
            response.data.data && response.data.data.length > 0 ? response.data.data : null;

          let associationProduct;

          productList.forEach((product) => {
            if (product) {
              const {
                _id,
                entityType: entity_type,
                entityId: entity_id,
                products,
                discounts,
                familyDiscounts,
                saleableItems,
                additionalQuestions,
                competitionProducts,
                competitionSaleableItems,
                competitionDiscounts,
                competitionAdditionalQuestions,
              } = product;

              if (entity_type === "") return;

              this.regiProduct = {
                _id,
                entity_type,
                entity_id,
                products: products.map((p) => ({
                  _id: p._id,
                  role: p.memberType,
                  gender: p.gender,
                  agefrom: p.ageFrom,
                  ageto: p.ageTo,
                  datefrom: p.dateFrom,
                  dateto: p.dateTo,
                  price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
                  price_int: p.price,
                  status: p.isRegoOpen,
                  payment: p.onlinePaymentRequired,
                })),
                additionalQuestions: additionalQuestions
                  ? additionalQuestions.map((p) => ({
                      _id: p._id,
                      type: p.type,
                      fieldName: p.fieldName,
                      question: p.question,
                      ageFrom: p.ageFrom,
                      ageTo: p.ageTo,
                      dateFrom: p.dateFrom,
                      dateTo: p.dateTo,
                      memberType: p.memberType,
                      gender: p.gender,
                      required: p.required,
                      isActive: p.isActive,
                      meta: {
                        options: _.get(p, "meta.options", []),
                        entityIds: _.get(p, "meta.entityIds", []),
                        entityType: _.get(p, "meta.entityType", undefined),
                      },
                    }))
                  : [],
                discounts: discounts
                  ? discounts.map((p) => ({
                      _id: p._id,
                      type: p.type === "dollar" ? "$" : "%",
                      discountName: p.name,
                      discountFrom: p.fromDate,
                      discountTo: p.toDate,
                      discountAmount:
                        p.type === "dollar"
                          ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                          : p.amount.toString(),
                      discountAmount_int: p.amount,
                      discountMemberType: p.memberType,
                    }))
                  : [],
                familyDiscounts: familyDiscounts
                  ? familyDiscounts.map((p) => ({
                      _id: p._id,
                      familyFrom: p.familyFrom,
                      familyTo: p.familyTo,
                      discountAmount: (parseFloat(p.amount) / 100).toFixed(2).toString(),
                      discountAmount_int: p.amount,
                      discountMemberType: p.memberType,
                    }))
                  : [],
                saleableitems: saleableItems
                  ? saleableItems.map((p) => ({
                      _id: p._id,
                      name: p.name,
                      description: p.description,
                      types: p.memberTypes,
                      price_int: p.price,
                      price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                      requireSizes: p.requireSizes || false,
                      mandatory: p.mandatory || false,
                      seasonPass: p.seasonPass || false,
                      seasonPassFrom: p.seasonPassFrom,
                      seasonPassTo: p.seasonPassTo,
                      seasonPassExComps: p.seasonPassExComps,
                      gender: p.gender || "",
                      ageFrom: p.ageFrom,
                      ageTo: p.ageTo,
                      dateFrom: p.dateFrom,
                      dateTo: p.dateTo,
                      url: p.url,
                      meta: {
                        options: _.get(p, "meta.options", []),
                        entityIds: _.get(p, "meta.entityIds", []),
                        entityType: _.get(p, "meta.entityType", undefined),
                      },
                    }))
                  : [],
                competitionProducts: competitionProducts
                  ? competitionProducts.map((p) => ({
                      _id: p._id,
                      competitionId: p.competitionId,
                      type: p.type,
                      price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
                      price_int: p.price,
                      status: p.isRegoOpen,
                      payment: p.onlinePaymentRequired,
                    }))
                  : [],
                competitionAdditionalQuestions: competitionAdditionalQuestions
                  ? competitionAdditionalQuestions.map((p) => ({
                      _id: p._id,
                      type: p.type,
                      fieldName: p.fieldName,
                      question: p.question,
                      competitionId: p.competitionId,
                      required: p.required,
                      isActive: p.isActive,
                      meta: {
                        options: _.get(p, "meta.options", []),
                      },
                    }))
                  : [],
                competitionSaleableItems: competitionSaleableItems
                  ? competitionSaleableItems.map((p) => ({
                      _id: p._id,
                      competitionId: p.competitionId,
                      name: p.name,
                      description: p.description,
                      price_int: p.price,
                      price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                      requireSizes: p.requireSizes || false,
                      mandatory: p.mandatory || false,
                      seasonPass: p.seasonPass || false,
                    }))
                  : [],
                competitionDiscounts: competitionDiscounts
                  ? competitionDiscounts.map((p) => ({
                      _id: p._id,
                      competitionId: p.competitionId,
                      type: p.type === "dollar" ? "$" : "%",
                      discountName: p.name,
                      discountFrom: p.fromDate,
                      discountTo: p.toDate,
                      discountAmount:
                        p.type === "dollar"
                          ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                          : p.amount.toString(),
                      discountAmount_int: p.amount,
                    }))
                  : [],
              };

              if(this.regiProduct.entity_type === "association"){
                associationProduct = this.regiProduct;
              }

              this.optionsdisabled = new Array(this.regiProduct.products.length).fill(false);
              this.discountoptionsdisabled = new Array(this.regiProduct.discounts.length).fill(
                false
              );
              this.familydiscountoptionsdisabled = new Array(
                this.regiProduct.familyDiscounts.length
              ).fill(false);
              this.saleableoptionsdisabled = new Array(this.regiProduct.saleableitems.length).fill(
                false
              );
            }
            let regPro = [];

            if (this.regiProduct.entity_type === "state") {
              regPro = this.regiProduct.products.filter(
                (p) => this.availableRoles.some((r) => r.type === p.role) && p.price_int > 0.01
              );

              this.stateRegoProduct = regPro;
              this.stateRegoSaleItems = this.regiProduct.saleableitems;
              this.stateRegoAQ = this.regiProduct.additionalQuestions;
            } else if (this.regiProduct.entity_type === "association") {
              regPro = this.regiProduct.products.filter((p) =>
                this.availableRoles.some((r) => r.type === p.role)
              );

              this.assocRegoProduct = regPro;
              this.assocRegoSaleItems = this.regiProduct.saleableitems;
              this.assocRegoAQ = this.regiProduct.additionalQuestions;
            } else if (this.regiProduct.entity_type === "region") {
              regPro = this.regiProduct.products.filter(
                (p) => this.availableRoles.some((r) => r.type === p.role) && p.price_int > 0.01
              );

              this.regionRegoProduct = regPro;
              this.regionRegoSaleItems = this.regiProduct.saleableitems;
              this.regionRegoAQ = this.regiProduct.additionalQuestions;
            } else if (this.regiProduct.entity_type === "national") {
              regPro = this.regiProduct.products.filter(
                (p) => this.availableRoles.some((r) => r.type === p.role) && p.price_int > 0.01
              );

              this.nationalRegoProduct = regPro;
              this.nationalRegoSaleItems = this.regiProduct.saleableitems;

              const matchedEntityIdAQ = this.regiProduct.additionalQuestions.filter((aq) =>
                aq.meta.entityIds.some((entity) => entity === _id)
              );

              const emptyEntityIdAQ = this.regiProduct.additionalQuestions.filter(
                (aq) => aq.meta.entityIds.length === 0
              );
              this.nationalRegoAQ = [...matchedEntityIdAQ, ...emptyEntityIdAQ];
            }
          });
          this.regiProduct = associationProduct;
          this.$store.commit("root/loading", false);
        });
    } else {
      this.$http.get("/nrl/api/v1/admin/registration-products").then((response) => {
        const product =
          response.data.data && response.data.data.length > 0 ? response.data.data[0] : null;

        if (product) {
          const {
            _id,
            entityType: entity_type,
            entityId: entity_id,
            products,
            discounts,
            familyDiscounts,
            saleableItems,
            additionalQuestions,
            competitionProducts,
            competitionSaleableItems,
            competitionDiscounts,
            competitionAdditionalQuestions,
          } = product;

          if (entity_type === "") return;

          this.regiProduct = {
            _id,
            entity_type,
            entity_id,
            products: products.map((p) => ({
              _id: p._id,
              role: p.memberType,
              gender: p.gender,
              agefrom: p.ageFrom,
              ageto: p.ageTo,
              datefrom: p.dateFrom,
              dateto: p.dateTo,
              price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
              price_int: p.price,
              status: p.isRegoOpen,
              payment: p.onlinePaymentRequired,
            })),
            additionalQuestions: additionalQuestions
              ? additionalQuestions.map((p) => ({
                  _id: p._id,
                  type: p.type,
                  fieldName: p.fieldName,
                  question: p.question,
                  ageFrom: p.ageFrom,
                  ageTo: p.ageTo,
                  dateFrom: p.dateFrom,
                  dateTo: p.dateTo,
                  memberType: p.memberType,
                  gender: p.gender,
                  required: p.required,
                  isActive: p.isActive,
                  meta: {
                    options: _.get(p, "meta.options", []),
                    entityIds: _.get(p, "meta.entityIds", []),
                    entityType: _.get(p, "meta.entityType", undefined),
                  },
                }))
              : [],
            discounts: discounts
              ? discounts.map((p) => ({
                  _id: p._id,
                  type: p.type === "dollar" ? "$" : "%",
                  discountName: p.name,
                  discountFrom: p.fromDate,
                  discountTo: p.toDate,
                  discountAmount:
                    p.type === "dollar"
                      ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                      : p.amount.toString(),
                  discountAmount_int: p.amount,
                  discountMemberType: p.memberType,
                }))
              : [],
            familyDiscounts: familyDiscounts
              ? familyDiscounts.map((p) => ({
                  _id: p._id,
                  familyFrom: p.familyFrom,
                  familyTo: p.familyTo,
                  discountAmount: (parseFloat(p.amount) / 100).toFixed(2).toString(),
                  discountAmount_int: p.amount,
                  discountMemberType: p.memberType,
                }))
              : [],
            saleableitems: saleableItems
              ? saleableItems.map((p) => ({
                  _id: p._id,
                  name: p.name,
                  description: p.description,
                  types: p.memberTypes,
                  price_int: p.price,
                  price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                  requireSizes: p.requireSizes || false,
                  mandatory: p.mandatory || false,
                  seasonPass: p.seasonPass || false,
                  seasonPassFrom: p.seasonPassFrom,
                  seasonPassTo: p.seasonPassTo,
                  seasonPassExComps: p.seasonPassExComps,
                  gender: p.gender || "",
                  ageFrom: p.ageFrom,
                  ageTo: p.ageTo,
                  dateFrom: p.dateFrom,
                  dateTo: p.dateTo,
                  url: p.url,
                  meta: {
                    options: _.get(p, "meta.options", []),
                    entityIds: _.get(p, "meta.entityIds", []),
                    entityType: _.get(p, "meta.entityType", undefined),
                  },
                }))
              : [],
            competitionProducts: competitionProducts
              ? competitionProducts.map((p) => ({
                  _id: p._id,
                  competitionId: p.competitionId,
                  type: p.type,
                  price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
                  price_int: p.price,
                  status: p.isRegoOpen,
                  payment: p.onlinePaymentRequired,
                }))
              : [],
            competitionAdditionalQuestions: competitionAdditionalQuestions
              ? competitionAdditionalQuestions.map((p) => ({
                  _id: p._id,
                  type: p.type,
                  fieldName: p.fieldName,
                  question: p.question,
                  competitionId: p.competitionId,
                  required: p.required,
                  isActive: p.isActive,
                  meta: {
                    options: _.get(p, "meta.options", []),
                  },
                }))
              : [],
            competitionSaleableItems: competitionSaleableItems
              ? competitionSaleableItems.map((p) => ({
                  _id: p._id,
                  competitionId: p.competitionId,
                  name: p.name,
                  description: p.description,
                  price_int: p.price,
                  price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                  requireSizes: p.requireSizes || false,
                  mandatory: p.mandatory || false,
                  seasonPass: p.seasonPass || false,
                }))
              : [],
            competitionDiscounts: competitionDiscounts
              ? competitionDiscounts.map((p) => ({
                  _id: p._id,
                  competitionId: p.competitionId,
                  type: p.type === "dollar" ? "$" : "%",
                  discountName: p.name,
                  discountFrom: p.fromDate,
                  discountTo: p.toDate,
                  discountAmount:
                    p.type === "dollar"
                      ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                      : p.amount.toString(),
                  discountAmount_int: p.amount,
                }))
              : [],
          };

          this.optionsdisabled = new Array(this.regiProduct.products.length).fill(false);
          this.discountoptionsdisabled = new Array(this.regiProduct.discounts.length).fill(false);
          this.familydiscountoptionsdisabled = new Array(
            this.regiProduct.familyDiscounts.length
          ).fill(false);
          this.saleableoptionsdisabled = new Array(this.regiProduct.saleableitems.length).fill(
            false
          );

          let regPro = [];

          if (this.regiProduct.entity_type === "state") {
            this.stateRegoProduct = this.regiProduct.products;
            this.stateRegoSaleItems = this.regiProduct.saleableitems;
            this.stateRegoAQ = this.regiProduct.additionalQuestions;
          } else if (this.regiProduct.entity_type === "association") {
            this.assocRegoProduct = this.regiProduct.products;
            this.assocRegoSaleItems = this.regiProduct.saleableitems;
            this.assocRegoAQ = this.regiProduct.additionalQuestions;
          } else if (this.regiProduct.entity_type === "region") {
            this.regionRegoProduct = this.regiProduct.products;
            this.regionRegoSaleItems = this.regiProduct.saleableitems;
            this.regionRegoAQ = this.regiProduct.additionalQuestions;
          } else if (this.regiProduct.entity_type === "national") {
            this.nationalRegoProduct = this.regiProduct.products;
            this.nationalRegoSaleItems = this.regiProduct.saleableitems;
            this.nationalRegoAQ = this.regiProduct.additionalQuestions;
          } else if (this.regiProduct.entity_type === "club") {
            this.clubRegoProduct = this.regiProduct.products;
            this.clubRegoSaleItems = this.regiProduct.saleableitems;
            this.clubRegoAQ = this.regiProduct.additionalQuestions;
          }
        }
        this.$store.commit("root/loading", false);
      });
    }
    // this.$http.get("/nrl/api/v1/admin/registration-products").then((response) => {
    //   const product =
    //     response.data.data && response.data.data.length > 0 ? response.data.data[0] : null;

    //   if (product) {
    //     const {
    //       _id,
    //       entityType: entity_type,
    //       entityId: entity_id,
    //       products,
    //       discounts,
    //       familyDiscounts,
    //       saleableItems,
    //       additionalQuestions,
    //       competitionProducts,
    //       competitionSaleableItems,
    //       competitionDiscounts,
    //       competitionAdditionalQuestions,
    //     } = product;

    //     this.regiProduct = {
    //       _id,
    //       entity_type,
    //       entity_id,
    //       products: products.map((p) => ({
    //         _id: p._id,
    //         role: p.memberType,
    //         gender: p.gender,
    //         agefrom: p.ageFrom,
    //         ageto: p.ageTo,
    //         datefrom: p.dateFrom,
    //         dateto: p.dateTo,
    //         price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
    //         price_int: p.price,
    //         status: p.isRegoOpen,
    //         payment: p.onlinePaymentRequired,
    //       })),
    //       additionalQuestions: additionalQuestions
    //         ? additionalQuestions.map((p) => ({
    //             _id: p._id,
    //             type: p.type,
    //             fieldName: p.fieldName,
    //             question: p.question,
    //             ageFrom: p.ageFrom,
    //             ageTo: p.ageTo,
    //             dateFrom: p.dateFrom,
    //             dateTo: p.dateTo,
    //             memberType: p.memberType,
    //             gender: p.gender,
    //             required: p.required,
    //             isActive: p.isActive,
    //             meta: {
    //               options: _.get(p, "meta.options", []),
    //               entityIds: _.get(p, "meta.entityIds", []),
    //               entityType: _.get(p, "meta.entityType", undefined),
    //             },
    //           }))
    //         : [],
    //       discounts: discounts
    //         ? discounts.map((p) => ({
    //             _id: p._id,
    //             type: p.type === "dollar" ? "$" : "%",
    //             discountName: p.name,
    //             discountFrom: p.fromDate,
    //             discountTo: p.toDate,
    //             discountAmount:
    //               p.type === "dollar"
    //                 ? (parseFloat(p.amount) / 100).toFixed(2).toString()
    //                 : p.amount.toString(),
    //             discountAmount_int: p.amount,
    //             discountMemberType: p.memberType,
    //           }))
    //         : [],
    //       familyDiscounts: familyDiscounts
    //         ? familyDiscounts.map((p) => ({
    //             _id: p._id,
    //             familyFrom: p.familyFrom,
    //             familyTo: p.familyTo,
    //             discountAmount: (parseFloat(p.amount) / 100).toFixed(2).toString(),
    //             discountAmount_int: p.amount,
    //             discountMemberType: p.memberType,
    //           }))
    //         : [],
    //       saleableitems: saleableItems
    //         ? saleableItems.map((p) => ({
    //             _id: p._id,
    //             name: p.name,
    //             description: p.description,
    //             types: p.memberTypes,
    //             price_int: p.price,
    //             price: (parseFloat(p.price) / 100).toFixed(2).toString(),
    //             requireSizes: p.requireSizes || false,
    //             mandatory: p.mandatory || false,
    //             seasonPass: p.seasonPass || false,
    //             seasonPassFrom: p.seasonPassFrom,
    //             seasonPassTo: p.seasonPassTo,
    //             seasonPassExComps: p.seasonPassExComps,
    //             gender: p.gender || "",
    //             ageFrom: p.ageFrom,
    //             ageTo: p.ageTo,
    //             dateFrom: p.dateFrom,
    //             dateTo: p.dateTo,
    //             url: p.url,
    //             meta: {
    //               options: _.get(p, "meta.options", []),
    //               entityIds: _.get(p, "meta.entityIds", []),
    //               entityType: _.get(p, "meta.entityType", undefined),
    //             },
    //           }))
    //         : [],
    //       competitionProducts: competitionProducts
    //         ? competitionProducts.map((p) => ({
    //             _id: p._id,
    //             competitionId: p.competitionId,
    //             type: p.type,
    //             price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
    //             price_int: p.price,
    //             status: p.isRegoOpen,
    //             payment: p.onlinePaymentRequired,
    //           }))
    //         : [],
    //       competitionAdditionalQuestions: competitionAdditionalQuestions
    //         ? competitionAdditionalQuestions.map((p) => ({
    //             _id: p._id,
    //             type: p.type,
    //             fieldName: p.fieldName,
    //             question: p.question,
    //             competitionId: p.competitionId,
    //             required: p.required,
    //             isActive: p.isActive,
    //             meta: {
    //               options: _.get(p, "meta.options", []),
    //             },
    //           }))
    //         : [],
    //       competitionSaleableItems: competitionSaleableItems
    //         ? competitionSaleableItems.map((p) => ({
    //             _id: p._id,
    //             competitionId: p.competitionId,
    //             name: p.name,
    //             description: p.description,
    //             price_int: p.price,
    //             price: (parseFloat(p.price) / 100).toFixed(2).toString(),
    //             requireSizes: p.requireSizes || false,
    //             mandatory: p.mandatory || false,
    //             seasonPass: p.seasonPass || false,
    //           }))
    //         : [],
    //       competitionDiscounts: competitionDiscounts
    //         ? competitionDiscounts.map((p) => ({
    //             _id: p._id,
    //             competitionId: p.competitionId,
    //             type: p.type === "dollar" ? "$" : "%",
    //             discountName: p.name,
    //             discountFrom: p.fromDate,
    //             discountTo: p.toDate,
    //             discountAmount:
    //               p.type === "dollar"
    //                 ? (parseFloat(p.amount) / 100).toFixed(2).toString()
    //                 : p.amount.toString(),
    //             discountAmount_int: p.amount,
    //           }))
    //         : [],
    //     };

    //     this.optionsdisabled = new Array(this.regiProduct.products.length).fill(false);
    //     this.discountoptionsdisabled = new Array(this.regiProduct.discounts.length).fill(false);
    //     this.familydiscountoptionsdisabled = new Array(
    //       this.regiProduct.familyDiscounts.length
    //     ).fill(false);
    //     this.saleableoptionsdisabled = new Array(this.regiProduct.saleableitems.length).fill(false);
    //   }
    //   this.$store.commit("root/loading", false);
    // });
  },
  methods: {
    handleClick(tab, event) {
      this.activeTab = tab.name;
      // set url with /this.activeTab
      this.$router.push({ path: `/registration-products/${tab.name}` });
    },
  },
};
</script>

<style scoped lang="scss"></style>
