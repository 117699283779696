<template>
  <div>
    <el-form
      label-position="top"
      :model="comp.meta"
      :rules="metaRules"
      id="metaRules"
      ref="metaRules"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="elite" label="Elite">
            <el-select v-model="comp.meta.elite" :disabled="isStateAdmin">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item prop="thirdPartyId" label="Third Party Competition ID (Stats ID)">
            <el-input
              autocomplete="off"
              placeholder="Enter third party ID (Stats ID)"
              v-model="comp.meta.thirdPartyId"
              :disabled="isStateAdmin"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-collapse v-model="activeNames">
      <el-collapse-item title="Team List Mailing List" name="1">
        <el-row :gutter="16">
          <el-form label-position="top" id="24HourTeamListSms" ref="24HourTeamListSms" class="m-1">
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item prop="enable24HourTeamListSms" label="Enable 24 Hour SMS Reminders">
                  <el-select v-model="comp.meta.enable24HourTeamListSms" :disabled="isStateAdmin">
                    <el-option label="Yes" :value="true" />
                    <el-option label="No" :value="false" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <action-buttons submit cancel @submit="updateMeta()" v-if="!isStateAdmin" />
          </el-form>
        </el-row>
        <el-form
          @submit.prevent.native="addEmailToList('tl')"
          label-position="top"
          :model="notification"
          :rules="notificationRules"
          id="notificationRules"
          ref="notificationRules"
        >
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item prop="newEmail">
                <el-input
                  class="add-email"
                  type="text"
                  autocomplete="off"
                  v-model="notification.newEmail"
                  placeholder="Add a contact email"
                  :disabled="isStateAdmin"
                >
                  <el-button
                    type="warning"
                    size="tiny"
                    slot="append"
                    icon="el-icon-circle-plus"
                    @click.prevent.native="addEmailToList('tl')"
                    :disabled="isStateAdmin"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <ul>
          <li shadow="hover" v-for="email in emailList" :key="email" class="small-card">
            <span> {{ email }} </span>
            <i class="el-icon-delete" @click="removeEmail(email, 'tl')" v-if="!isStateAdmin"></i>
          </li>
        </ul>
      </el-collapse-item>
      <el-collapse-item title="Report Mailing List" name="2">
        <el-form
          @submit.prevent.native="addEmailToList('report')"
          label-position="top"
          :model="notification"
          :rules="reportListRules"
          id="reportListRules"
          ref="reportListRules"
        >
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item prop="newReportEmail">
                <el-input
                  class="add-email"
                  type="text"
                  autocomplete="off"
                  v-model="notification.newReportEmail"
                  placeholder="Add a contact email"
                  :disabled="isStateAdmin"
                >
                  <el-button
                    type="warning"
                    size="tiny"
                    slot="append"
                    icon="el-icon-circle-plus"
                    @click.prevent.native="addEmailToList('report')"
                    :disabled="isStateAdmin"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <ul>
          <li shadow="hover" v-for="email in reportList" :key="email" class="small-card">
            <span> {{ email }} </span>
            <i
              class="el-icon-delete"
              @click="removeEmail(email, 'report')"
              v-if="!isStateAdmin"
            ></i>
          </li>
        </ul>
      </el-collapse-item>
      <el-collapse-item title="MyMedical Settings" name="3">
        <el-form
          label-position="top"
          :model="comp.meta"
          :rules="metaRules"
          id="metaRules"
          ref="metaRules"
          class="m-1"
        >
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item prop="enableMedical" label="Enable MyMedical Access">
                <el-select v-model="comp.meta.enableMedical" :disabled="isStateAdmin">
                  <el-option label="Yes" :value="true" />
                  <el-option label="No" :value="false" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="comp.meta.enableMedical">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="medicalBaselineTemplate" label="Baseline Template">
                  <el-select v-model="comp.meta.medicalBaselineTemplate" :disabled="isStateAdmin">
                    <el-option
                      v-for="{ _id, version } in templates.baseline"
                      :key="_id"
                      :label="`${_id}_${version}`"
                      :value="_id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="medicalHiaTemplate" label="HIA Template">
                  <el-select v-model="comp.meta.medicalHiaTemplate" :disabled="isStateAdmin">
                    <el-option
                      v-for="{ _id, version } in templates.hia"
                      :key="_id"
                      :label="`${_id}_${version}`"
                      :value="_id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="medicalFollowUpTemplate" label="Follow Up Template">
                  <el-select v-model="comp.meta.medicalFollowUpTemplate" :disabled="isStateAdmin">
                    <el-option
                      v-for="{ _id, version } in templates['follow-up']"
                      :key="_id"
                      :label="`${_id}_${version}`"
                      :value="_id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="medicalRtpTemplate" label="Return to Play Template">
                  <el-select v-model="comp.meta.medicalRtpTemplate" :disabled="isStateAdmin">
                    <el-option
                      v-for="{ _id, version } in templates.rtp"
                      :key="_id"
                      :label="`${_id}_${version}`"
                      :value="_id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-row>
        </el-form>
        <el-form
          @submit.prevent.native="addEmailToMyMedicalList()"
          label-position="top"
          :rules="emailListRules"
          id="emailListRules"
          ref="emailListRules"
        >
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item prop="newEmail">
                <el-input
                  class="add-email"
                  type="text"
                  autocomplete="off"
                  v-model="newEmailData"
                  placeholder="Add an email"
                >
                  <el-button
                    type="warning"
                    size="tiny"
                    slot="append"
                    icon="el-icon-circle-plus"
                    @click.prevent.native="addEmailToMyMedicalList()"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <ul>
          <li shadow="hover" v-for="email in emailListData" :key="email" class="small-card">
            <span>{{ email }}</span>
            <i
              class="el-icon-delete tw-border-2 tw-rounded-full tw-bg-white tw-cursor-pointer tw-p-2 tw-ml-2"
              @click="removeMyMedicalEmail(email)"
            ></i>
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>

    <action-buttons
      submit
      cancel
      @submit="updateMeta()"
      @cancel="cancelForm()"
      v-if="!isStateAdmin"
    />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, roles } from "../../utils/constants";
import { validateEmail } from "../../utils/utilities";
import { EventBus } from "../../bus";

const validateContactEmail = (rule, value, callback) => {
  if (!value || !validateEmail(value)) {
    return callback(new Error(errormsg.email));
  }
  return callback();
};

export default {
  data() {
    return {
      activeNames: [],
      emailList:
        this.comp && this.comp.meta && this.comp.meta.notificationList
          ? this.comp.meta.notificationList
          : [],
      reportList:
        this.comp && this.comp.meta && this.comp.meta.reportList ? this.comp.meta.reportList : [],
      notification: { newEmail: "", newReportEmail: "" },
      notificationRules: {
        newEmail: {
          message: errormsg.input_option,
          trigger: "submit",
          validator: validateContactEmail,
        },
      },
      reportListRules: {
        newReportEmail: {
          message: errormsg.input_option,
          trigger: "submit",
          validator: validateContactEmail,
        },
      },
      emailListRules: {
        newEmail: {
          message: errormsg.input_option,
          trigger: "submit",
          validator: validateContactEmail,
        },
      },
      newEmail: "",
      newEmailData: "",
      emailListData: [],
      metaRules: {
        elite: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        enableMedical: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
      },
      templates: {},
    };
  },
  components: { ActionButtons },
  props: {
    competition: { type: Object, required: true },
  },
  async mounted() {
    if (this.isSuperAdminNRL) {
      try {
        this.$store.commit("root/loading", true);
        const res = await this.$http.get("/nrl/api/v1/admin/assessment-templates");
        if (
          res &&
          res.data &&
          res.data.templates_response &&
          Array.isArray(res.data.templates_response)
        ) {
          for (const { _id, templates } of res.data.templates_response) {
            this.templates[_id] = templates;
          }
        }
        this.emailListData = this.competition.meta.mymedicalEmailList
          ? this.competition.meta.mymedicalEmailList
          : [];
        this.$store.commit("root/loading", false);
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError(error);
      }
    }
  },
  updated() {
    if (this.comp && this.comp.meta) {
      if (this.comp.meta.notificationList) this.emailList = this.comp.meta.notificationList;
      if (this.comp.meta.reportList) this.reportList = this.comp.meta.reportList;
    }
  },
  methods: {
    removeEmail(email, type) {
      const emailList = this[type === "tl" ? "emailList" : "reportList"];
      const filtered = emailList.filter((mail) => mail !== email);
      this.$store.commit("root/loading", true);
      const update =
        type === "tl"
          ? { meta: { ...this.comp.meta, notificationList: filtered } }
          : { meta: { ...this.comp.meta, reportList: filtered } };
      const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
      this.$http
        .put(url, update)
        .then((response) => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
          EventBus.$emit("updateCompetition", response.data.data);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    addEmailToList(type) {
      let divRef = "notificationRules";
      if (type === "report") divRef = "reportListRules";
      this.$refs[divRef].validate((valid) => {
        if (valid) {
          const newEmail = this.notification[type === "tl" ? "newEmail" : "newReportEmail"];
          this[type === "tl" ? "emailList" : "reportList"].push(newEmail);
          this.$store.commit("root/loading", true);
          const update =
            type === "tl"
              ? { meta: { ...this.comp.meta, notificationList: this.emailList } }
              : { meta: { ...this.comp.meta, reportList: this.reportList } };
          const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
          this.$http
            .put(url, update)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
              this.notification.newEmail = "";
              this.notification.newReportEmail = "";
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
          this.$set(this.notification, "newEmail", "");
        }
      });
    },
    updateMeta() {
      this.$refs.metaRules.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const update = { meta: { ...this.comp.meta, notificationList: this.emailList } };
          const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
          this.$http
            .put(url, update)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
    addEmailToMyMedicalList() {
      this.$store.commit("root/loading", true);
      if (this.newEmailData) {
        this.$http
          .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/mymedical/add/email`, {
            email: this.newEmailData,
          })
          .then((response) => {
            if (this.emailListData && this.emailListData.length > 0) {
              this.emailListData.push(this.newEmailData);
            } else {
              this.emailListData = [this.newEmailData];
            }
            this.newEmailData = "";
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error adding email:", error);
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    },
    removeMyMedicalEmail(email) {
      this.$store.commit("root/loading", true);
      const index = this.emailListData.indexOf(email);
      if (index !== -1) {
        this.$http
          .post(`/nrl/api/v1/admin/competitions/${this.competition._id}/mymedical/remove/email`, {
            email,
          })
          .then((response) => {
            this.emailListData.splice(index, 1);
            this.$store.commit("root/loading", false);
          })
          .catch((error) => {
            console.error("Error removing email:", error);
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    },
  },
  computed: {
    comp() {
      return { ...this.competition, meta: this.competition.meta || {} };
    },
    isStateAdmin() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.stateAdmin && national_id === 32;
    },
    isSuperAdminNRL() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.superAdmin && national_id === 32;
    },
  },
  watch: {
    comp() {
      this.emailListData = this.competition.meta.mymedicalEmailList
        ? this.competition.meta.mymedicalEmailList
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}

.small-card {
  margin: 0.5rem;
}

.add-email {
  text-align: center;
}

.margin-bottom {
  margin-bottom: 2rem;
}
</style>
