<template>
  <div>
    <el-dialog :visible.sync="matchFilterDialog" title="Filter Matches" center>
      <div class="tw-flex p-2 tw-flex-col tw-items-center tw-gap-y-2.5">
        <el-select
          class="filter-w"
          :disabled="bulkEditing === true"
          v-model="filter.round"
          placeholder="Select a Round"
          filterable
        >
          <el-option label="All Rounds" key="all-rounds" :value="undefined"> </el-option>
          <el-option v-for="round in availableRounds" :label="round" :key="round" :value="round">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="filter.dateRange"
          :disabled="bulkEditing === true"
          type="daterange"
          value-format="timestamp"
          range-separator="|"
          start-placeholder="Start date"
          end-placeholder="End date"
        ></el-date-picker>
        <el-select
          class="filter-w"
          :disabled="bulkEditing === true"
          v-model="filter.venue"
          placeholder="Select a Venue"
          filterable
        >
          <el-option label="All Venues" key="all-venues" :value="undefined"> </el-option>
          <el-option
            v-for="venue in allVenues"
            :label="venue.name"
            :key="venue._id"
            :value="venue._id"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="filterMatches" type="success" class=""> search </el-button>
        <el-button @click="filter = {}">Clear</el-button>
      </span>
    </el-dialog>
    <div
      class="tw-gap-4 tw-flex-row tw-flex tw-justify-between tw-w-full tw-max-w-5xl tw-pb-8 tw-border-b tw-border-gray-200"
    >
    <div 
    class="tw-w-full"
    >
      <el-select
        :disabled="bulkEditing === true"
        v-model="currentCompetitions"
        @change="fetchMatchesByCompetitions"
        placeholder="Select competitions"
        filterable
        multiple
      >
        <el-option
          v-for="item in availableCompetitions"
          :label="item.name"
          :key="item._id"
          :value="item._id"
        >
        </el-option>
      </el-select>
    </div>

      <div
        id="buttons"
        class="tw-flex tw-flex-col tw-flex-wrap tw-justify-end tw-col-span-2 tw-gap-4 sm:tw-flex-row sm:tw-gap-4"
      >
        <el-button
          v-if="currentCompetitions && !bulkEditing"
          @click="matchFilterDialog = true"
          type="success"
          icon="el-icon-search"
          class="tw-w-48 tw-h-10"
        >
          Filters
          <span v-if="currentFilters" class="active-filters">{{ currentFilters }}</span>
        </el-button>
        <el-button
          v-if="currentCompetitions && tableData.length > 0 && bulkEditing === false"
          @click="beginBulkEdit"
          type="success"
          icon="el-icon-setting"
          class="tw-w-48 tw-h-10"
        >
          Bulk Edit
        </el-button>
        <div v-if="competitionType && competitionType.isAllowedToUseFinals === false">
        </div>
        <el-button
          v-if="currentCompetitions && bulkEditing === true"
          @click="saveBulkEdit"
          type="success"
          icon="el-icon-setting"
          class="tw-w-48 tw-h-10 bulk-edit"
        >
          Save Edits
        </el-button>
        <el-button
          v-if="currentCompetitions && bulkEditing === true"
          @click="cancelBulkEdit"
          class="tw-w-48 tw-h-10 bulk-edit"
        >
          Cancel
        </el-button>
      </div>
    </div>
    <data-tables
      v-loading="loading"
      :key="page"
      :data="bulkEditing === true ? editData : tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size.sync="pageSize"
      :current-page.sync="page"
      class="data-table"
    >
    <el-table-column fixed align="left" prop="competition.name" label="Competition" width="100" v-if="currentCompetitions.length > 1">
    </el-table-column>
      <el-table-column fixed align="left" prop="round.type" label="Round Type" width="100">
      </el-table-column>
      <el-table-column fixed align="left" prop="round.number" label="Round" width="100">
        <template slot-scope="scope">
          <el-input
            :id="`round-input-${scope.$index}`"
            class="round-edit-input"
            v-if="bulkEditing && !readOnly"
            autocomplete="off"
            placeholder="Round number"
            type="number"
            v-model="scope.row.round.number"
            @change="markAsEdited(scope)"
            min="1"
          ></el-input>
          <span v-else class="round-edit-span">{{ scope.row.round.number }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Home Team" width="200">
        <template slot-scope="scope">
          <el-select
            :disabled="scope.row.meta.isBye && !scope.row.homeTeam"
            v-if="bulkEditing && !readOnly"
            v-model="scope.row.homeTeam"
            value-key="_id"
            name="name"
            @change="markAsEdited(scope)"
            filterable
            :placeholder="
              scope.row.meta.isBye &&
              (!scope.row.homeTeam || (scope.row.homeTeam && !scope.row.homeTeam.name))
                ? 'BYE'
                : 'Select home team'
            "
          >
            <el-option v-for="item in allTeams" :key="item._id" :label="item.name" :value="item">
            </el-option>
          </el-select>
          <span v-else>
            {{
              scope.row.homeTeam && scope.row.homeTeam.name
                ? scope.row.homeTeam.name
                : scope.row.meta.isBye
                ? "BYE"
                : "NA"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Away Team" width="200">
        <template slot-scope="scope">
          <el-select
            :disabled="scope.row.meta.isBye && !scope.row.awayTeam"
            v-if="bulkEditing && !readOnly"
            v-model="scope.row.awayTeam"
            value-key="_id"
            name="name"
            @change="markAsEdited(scope)"
            filterable
            :placeholder="
              scope.row.meta.isBye &&
              (!scope.row.awayTeam || (scope.row.awayTeam && !scope.row.awayTeam.name))
                ? 'BYE'
                : 'Select away team'
            "
          >
            <el-option v-for="item in allTeams" :key="item._id" :label="item.name" :value="item">
            </el-option>
          </el-select>
          <span v-else>
            {{
              scope.row.awayTeam && scope.row.awayTeam.name
                ? scope.row.awayTeam.name
                : scope.row.meta.isBye
                ? "BYE"
                : "NA"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :formatter="dateTimeFormatter" align="left" label="Date & Time" width="250">
        <template slot-scope="scope">
          <el-date-picker
            format="yyyy/MM/dd HH:mm"
            v-if="bulkEditing && !readOnly"
            value-format="yyyy-MM-dd HH:mm"
            v-model="scope.row.strTime"
            @change="markAsEdited(scope)"
            type="datetime"
            placeholder="Select date and time"
            default-time="12:00:00"
          >
          </el-date-picker>
          <span v-else> {{ dateTimeFormatter(scope.row) }} </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Venue" width="250">
        <template slot-scope="scope">
          <el-select
            v-if="bulkEditing && !readOnly"
            :remote="true"
            :remote-method="filterVenues"
            @change="markAsEdited(scope)"
            v-model="scope.row.venue"
            value-key="_id"
            name="name"
            placeholder="Please select a venue"
            filterable
          >
            <el-option v-for="item in allVenues" :key="item._id" :label="item.name" :value="item">
            </el-option>
          </el-select>
          <span v-else>
            {{
              scope.row.venue && scope.row.venue.suburb
                ? scope.row.venue.name + " - " + scope.row.venue.suburb
                : scope.row.venue.name
                ? scope.row.venue.name
                : "NA"
            }}
          </span>
          <span
            v-if="
              scope.row.homeTeam.name &&
              scope.row.awayTeam.name &&
              scope.row.venue &&
              !scope.row.venue.name &&
              submitted
            "
            style="color: #f56c6c"
          >
            Please select an option
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Field No" width="210">
        <template slot-scope="scope">
          <el-input
            maxlength="10"
            v-if="bulkEditing && !readOnly"
            @change="markAsEdited(scope)"
            v-model="scope.row.meta.fieldNo"
            placeholder="Field No"
          >
          </el-input>
          <span v-else>
            {{ scope.row.meta && scope.row.meta.fieldNo ? scope.row.meta.fieldNo : "NA" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :formatter="matchStatusFormatter"
        prop="status"
        label="Match Status"
        width="250"
      >
      </el-table-column>
      <el-table-column
        prop="eliteMatchStatus"
        label="Elite Game Status"
        width="250"
        v-if="currentCompetitionData && currentCompetitionData.meta.elite"
      >
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        align="left"
        prop="modifiedAt"
        label="Last Modified"
        width="250"
      >
      </el-table-column>
      <el-table-column
        align="left"
        label="Summary"
        fixed
        width="300"
        v-if="bulkEditing && readOnly"
      >
        <template slot-scope="scope">
          <p>
            {{
              scope.row.homeTeam && scope.row.homeTeam.name
                ? scope.row.homeTeam.name
                : scope.row.meta.isBye
                ? "BYE"
                : "NA"
            }}
            vs
            {{
              scope.row.awayTeam && scope.row.awayTeam.name
                ? scope.row.awayTeam.name
                : scope.row.meta.isBye
                ? "BYE"
                : "NA"
            }}
          </p>
          <p>
            Venue:
            {{
              scope.row.venue && scope.row.venue.suburb
                ? scope.row.venue.name + " - " + scope.row.venue.suburb
                : scope.row.venue.name
                ? scope.row.venue.name
                : "NA"
            }}
          </p>
          <p>
            Date Time: {{ dateTimeFormatter(scope.row) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Referees" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.referees &&
              scope.row.referees[0] &&
              scope.row.referees[0].firstName + " " + scope.row.referees[0].lastName
            }}
            {{
              scope.row.referees &&
              scope.row.referees[1] &&
              ", " + scope.row.referees[1].firstName + " " + scope.row.referees[1].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.referees[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select a Referee'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              :disabled="!bulkEditing"
              v-model="scope.row.referees[1]"
              value-key="_id"
              name="name"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="
                scope.row.meta.isBye &&
                (!scope.row.awayTeam || (scope.row.awayTeam && !scope.row.awayTeam.name))
                  ? 'BYE'
                  : 'Select a Referee'
              "
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Touch Judges" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.touchJudges &&
              scope.row.touchJudges[0] &&
              scope.row.touchJudges[0].firstName + " " + scope.row.touchJudges[0].lastName
            }}
            {{
              scope.row.touchJudges &&
              scope.row.touchJudges[1] &&
              ", " + scope.row.touchJudges[1].firstName + " " + scope.row.touchJudges[1].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.touchJudges[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select a Touch Judge'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="scope.row.touchJudges[1]"
              value-key="_id"
              name="name"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="
                scope.row.meta.isBye &&
                (!scope.row.awayTeam || (scope.row.awayTeam && !scope.row.awayTeam.name))
                  ? 'BYE'
                  : 'Select a Touch Judge'
              "
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Referee Coaches" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.refereeCoaches &&
              scope.row.refereeCoaches[0] &&
              scope.row.refereeCoaches[0].firstName + " " + scope.row.refereeCoaches[0].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.refereeCoaches[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select a Referee Coach'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Standby Referees" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.standbyReferees &&
              scope.row.standbyReferees[0] &&
              scope.row.standbyReferees[0].firstName + " " + scope.row.standbyReferees[0].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.standbyReferees[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select a Standby Referee'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Interchange Official" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.standByTouchJudges &&
              scope.row.standByTouchJudges[0] &&
              scope.row.standByTouchJudges[0].firstName +
                " " +
                scope.row.standByTouchJudges[0].lastName
            }}
            {{
              scope.row.standByTouchJudges &&
              scope.row.standByTouchJudges[1] &&
              ", " + scope.row.standByTouchJudges[1].firstName + " " + scope.row.standByTouchJudges[1].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.standByTouchJudges[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select an Interchange Official'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="scope.row.standByTouchJudges[1]"
              value-key="_id"
              name="name"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="
                scope.row.meta.isBye &&
                (!scope.row.awayTeam || (scope.row.awayTeam && !scope.row.awayTeam.name))
                  ? 'BYE'
                  : 'Select an Interchange Official'
              "
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Referee Trainers" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.refereeTrainers &&
              scope.row.refereeTrainers[0] &&
              scope.row.refereeTrainers[0].firstName + " " + scope.row.refereeTrainers[0].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.refereeTrainers[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select a Referee Trainer'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="In Goal Judges" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.inGoalJudges &&
              scope.row.inGoalJudges[0] &&
              scope.row.inGoalJudges[0].firstName + " " + scope.row.inGoalJudges[0].lastName
            }}
            {{
              scope.row.inGoalJudges &&
              scope.row.inGoalJudges[1] &&
              ", " + scope.row.inGoalJudges[1].firstName + " " + scope.row.inGoalJudges[1].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.inGoalJudges[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select an In Goal Judge'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="scope.row.inGoalJudges[1]"
              value-key="_id"
              name="name"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="
                scope.row.meta.isBye &&
                (!scope.row.awayTeam || (scope.row.awayTeam && !scope.row.awayTeam.name))
                  ? 'BYE'
                  : 'Select an In Goal Judge'
              "
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Video Referees" width="300">
        <template slot-scope="scope">
          <p v-if="!bulkEditing || !readOnly">
            {{
              scope.row.videoReferees &&
              scope.row.videoReferees[0] &&
              scope.row.videoReferees[0].firstName + " " + scope.row.videoReferees[0].lastName
            }}
            {{
              scope.row.videoReferees &&
              scope.row.videoReferees[1] &&
              ", " +
                scope.row.videoReferees[1].firstName +
                " " +
                scope.row.videoReferees[1].lastName
            }}
          </p>
          <span v-else>
            <el-select
              class="mb-1"
              v-model="scope.row.videoReferees[0]"
              value-key="_id"
              name="firstName"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="scope.row.meta.isBye ? 'BYE' : 'Select a Video Referee'"
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="scope.row.videoReferees[1]"
              value-key="_id"
              name="name"
              @change="markAsEdited(scope)"
              filterable
              clearable
              :placeholder="
                scope.row.meta.isBye &&
                (!scope.row.awayTeam || (scope.row.awayTeam && !scope.row.awayTeam.name))
                  ? 'BYE'
                  : 'Select a Video Referee'
              "
            >
              <el-option
                v-for="item in referees"
                :key="item._id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { errormsg, matchStatus, nationals } from "../../utils/constants";

const _ = require("lodash");

const processEmpty = (obj) => obj || { _id: null, name: null, venueTimezone: null };
const createDateTimeStr = (m) => {
  try {
    const tzGuess = moment.tz.guess();
    const venueTz =
      m.venue && m.venue.venueTimezone
        ? m.venue.venueTimezone
        : tzGuess
        ? tzGuess
        : "Australia/Sydney";
    return m && m.dateTime ? moment.tz(m.dateTime, venueTz).format("YYYY-MM-DD HH:mm") : undefined;
  } catch (error) {}
};

// Check if any regular round match has same home and away team
const checkSameTeam = (matches) => {
  const sameTeamRD = _.find(
    matches,
    (match) =>
      match.homeTeam._id &&
      match.awayTeam._id &&
      match.homeTeam._id !== "TBA" &&
      match.homeTeam._id === match.awayTeam._id
  );
  return !sameTeamRD;
};

const checkTbaByeMatch = (matches) =>
  matches.some(
    (match) =>
      (match.homeTeam._id === "TBA" && match.awayTeam._id === "BYE") ||
      (match.homeTeam._id === "BYE" && match.awayTeam._id === "TBA")
  );

const validRoundNum = (matches) => {
  const reg = new RegExp("^[0-9]+$");
  const valid = matches.every((match) => reg.test(match.round.number));
  return valid;
};

export default {
  name: "MatchesList",
  props: {},
  data() {
    return {
      competitionType: {},
      matchFilterDialog: false,
      nationals,
      availableCompetitions: [],
      selectedCompetitions: undefined,
      bulkEditing: false,
      currentCompetitions: [],
      currentCompetition: null,
      currentCompetitionData: null,
      currentCompetitionLadder: null,
      allTeams: [],
      allVenues: [],
      filter: {},
      loading: false,
      tableData: [],
      editData: [],
      originalData: [],
      pageSize: 15,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [15, 20, 25],
      },
      page: 1,
      matchStatus,
      submitted: false,
      readOnly: false,
      referees: [],
    };
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((response) => {
        this.availableCompetitions =
          response.data && response.data.data ? response.data.data.filter((c) => c.isActive) : [];
        this.getReferees();
        this.loading = false;
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    getReferees() {
      const url = `/nrl/api/v1/admin/referees`;
      this.$http
        .get(url)
        .then((response) => {
          this.referees = response.data.members;
        })
        .catch(() => {
          this.$customError();
        });
    },
    filterMatches() {
      const filteredMatches = this.originalData.filter((m) => {
        let match = true;
        const { round, dateRange, venue } = this.filter;

        if (round) match = match && m.round && m.round.displayName === round;
        if (dateRange && Array.isArray(dateRange) && dateRange.length === 2) {
          const [start, end] = dateRange;
          match = match && start && end && m.dateTime && m.dateTime >= start && m.dateTime <= end;
        }
        if (venue) match = match && m.venue && m.venue._id === venue;
        return match;
      });
      const { round, dateRange, venue } = this.filter;
      this.$store.commit("matchlist/update", { filter: { round, dateRange, venue } });
      this.tableData = filteredMatches;
      this.matchFilterDialog = false;
    },
    updateMatches(matches) {
      this.$store.commit("root/loading", true);
      matches.forEach((match) => {
        const url = `/nrl/api/v1/admin/matches/${match._id}`;
        this.$http
          .put(url, match)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      });
    },
    markAsEdited(scope) {
      if (
        scope.column &&
        scope.column.label === "Date & Time" &&
        scope.row &&
        scope.row.strTime &&
        scope.row.dateTime
      ) {
        const tzGuess = moment.tz.guess();
        const venueTz =
          scope.row.venue && scope.row.venue.venueTimezone
            ? scope.row.venue.venueTimezone
            : tzGuess
            ? tzGuess
            : "Australia/Sydney";
        const { strTime, dateTime } = scope.row;
        const savedMatchTime = moment.tz(strTime, venueTz).valueOf();
        scope.row.dateTime = savedMatchTime;
      }
      scope.row.edited = true;
      if (scope.row.homeTeam._id === scope.row.awayTeam._id) {
        this.$customError(errormsg.duplicate_team);
      }
    },
    beginBulkEdit() {
      this.editData = _.cloneDeep(this.tableData).filter((item) => {
        if (item.homeTeam == null) {
          item.homeTeam = {
            name: null,
            venueTimezone: null,
            _id: null,
          };
        } else if (item.awayTeam == null) {
          item.awayTeam = {
            name: null,
            venueTimezone: null,
            _id: null,
          };
        }
        return item;
      });
      this.bulkEditing = true;
    },
    cancelBulkEdit() {
      this.editData = [];
      this.bulkEditing = false;
    },
    transformAppointments(appointments, role) {
      if (appointments && appointments.length > 0) {
        return appointments
          .filter(
            (app) =>
              app._id !== undefined && app.firstName !== undefined && app.lastName !== undefined
          )
          .map((app) => ({
            role: role,
            _id: app._id,
            firstName: app.firstName,
            lastName: app.lastName,
          }));
      }
      return [];
    },
    saveBulkEdit() {
      // this.$store.commit("root/loading", true);
      this.submitted = true;

      // do not allow TBA and BYE in a match
      if (checkTbaByeMatch(this.editData)) {
        this.$store.commit("root/loading", false);
        this.$customError(errormsg.draw_tbabyematch);
        return false;
      }

      // Check if any regular round match has same home and away team
      if (!checkSameTeam(this.editData)) {
        this.$store.commit("root/loading", false);
        this.$customError(errormsg.draw_sameteam);
        return false;
      }

      if (!validRoundNum(this.editData)) {
        this.$store.commit("root/loading", false);
        this.$customError("Round value must only use integers 0-9");
        return false;
      }

      const editPromises = [];
      const now = moment().valueOf();
      let validateVenue = this.editData.find(
        (match) => match.homeTeam.name && match.awayTeam.name && match.venue && !match.venue.name
      );
      if (validateVenue) {
        return false;
      }
      this.editData.forEach((match) => {
        /**
         * Add all types of referees to array called match.appointments
         */
        match.appointments = [
          ...this.transformAppointments(match.referees, "referee"),
          ...this.transformAppointments(match.touchJudges, "touch-judge"),
          ...this.transformAppointments(match.inGoalJudges, "in-goal-judge"),
          ...this.transformAppointments(match.videoReferees, "video-referee"),
          ...this.transformAppointments(match.refereeCoaches, "referee-coach"),
          ...this.transformAppointments(match.standbyReferees, "standby-referee"),
          ...this.transformAppointments(match.standByTouchJudges, "standby-touch-judge"),
          ...this.transformAppointments(match.refereeTrainers, "referee-trainer"),
        ];
        if (match.homeTeam.name && match.awayTeam.name) {
          match.meta.isBye = false;
          match.meta.isTba = false;
          // match
        }
        if (match.homeTeam._id === 0 || match.awayTeam._id === 0) {
          match.meta.isBye = true;
          if (match.homeTeam._id === 0) {
            match.homeTeam = null;
          } else {
            match.awayTeam = null;
          }
        }
        if (match.edited && match.round.number && typeof match.round.number === "string") {
          const roundNum = parseInt(match.round.number);
          if (match.round.type === "Regular") match.round.displayName = `Round ${roundNum}`;
          match.round.number = roundNum;
        }
        if (match.edited) {
          match.modifiedAt = now;
          editPromises.push(this.$http.put(`/nrl/api/v1/admin/matches/${match._id}`, match));
        }
      });
      this.tableData = _.cloneDeep(this.editData).sort(
        (a, b) =>
          b.round.type.localeCompare(a.round.type) ||
          a.round.number - b.round.number ||
          a.dateTime - b.dateTime
      );
      this.editData = [];
      Promise.all(editPromises)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.bulkEditing = false;
          this.submitted = false;
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.bulkEditing = false;
          this.$customError();
        });
      return true;
    },
    filterVenues(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then((response) => {
              this.allVenues = response.data.data.map((venue) => ({
                _id: venue._id,
                name: venue.name,
              }));
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allVenues = [];
      }
    },
    fetchMatchesByCompetitions() {
      const { currentCompetitions } = this;
      if(!currentCompetitions || currentCompetitions.length === 0) {
        return;
      }
      const activeFilters = this.$store.getters["matchlist/activeFilters"];
      const values = activeFilters ? Object.values(activeFilters) : [];
      if (values && Array.isArray(values) && values.length) {
        const check = values.filter((v) => v);
        if (check.length) {
          this.filter = activeFilters;
        } else this.filter = {};
      }
      this.loading = true;
      Promise.all([
        this.$http.get(`/nrl/api/v1/admin/referee/matches/competition/${currentCompetitions}`),
        this.$http.get(`/nrl/api/v1/admin/referee/competitions/${currentCompetitions}`),
      ])
        .then(([matchesRes, compRes]) => {
          this.tableData = this.originalData = matchesRes.data.data
            .map((match) => ({
              ...match,
              homeTeam: processEmpty(match.homeTeam),
              awayTeam: processEmpty(match.awayTeam),
              venue: processEmpty(match.venue),
              dateTime: match.dateTime || null,
              strTime: createDateTimeStr(match),
            }))
            .sort(
              (a, b) =>
                b.round.type.localeCompare(a.round.type) ||
                a.round.number - b.round.number ||
                a.dateTime - b.dateTime
            );
          this.filterMatches();

          this.allVenues = matchesRes.data.data
            .map((match) => match.venue)
            .filter(
              (venue, index, self) =>
                venue &&
                venue._id &&
                venue.name &&
                index ===
                  self.findIndex((t) => venue && t && t._id === venue._id && t.name === venue.name)
            )
            .sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
              return 0;
            });

          this.currentCompetitionData = compRes.data.data;
          /**
           * Mark if this competition orgtree.association._id is not same as current role entityId
           */
          if (
            this.currentCompetitionData.orgtree.association._id !==
              this.$store.getters["user/activeRole"].entity._id &&
            this.$store.getters["user/activeRole"].type === 4
          ) {
            this.readOnly = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.$customError();
          this.loading = false;
        });
    },
    matchStatusFormatter(row) {
      if (row.status && matchStatus.find((status) => (status.type === row.status || status.name === row.status))) {
        return matchStatus.find((status) => (status.type === row.status || status.name === row.status)).name;
      }
      return "NA";
    },
    modifiedFormatter(row) {
      if (row.modifiedAt) {
        return moment(row.modifiedAt).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    dateTimeFormatter(row) {
      if (row.dateTime) {
        const tzGuess = moment.tz.guess();
        const venueTz =
          row.venue && row.venue.venueTimezone
            ? row.venue.venueTimezone
            : tzGuess
            ? tzGuess
            : "Australia/Sydney";
        return moment.tz(row.dateTime, venueTz).format("MMMM Do YYYY, h:mm a (z)");
      }
      return "NA";
    },
  },
  computed: {
    availableRounds() {
      const uniqueRounds = [...new Set(this.originalData.map((match) => match.round.displayName))];
      return uniqueRounds;
    },
    currentFilters() {
      const activeFilters = this.$store.getters["matchlist/activeFilters"];
      const counter = Object.values(activeFilters).filter((q) => q).length;
      return counter;
    },
    totalRows() {
      const total = this.tableData.length || 0;
      return total;
    },
  },
  watch: {
    currentCompetition(val) {
      this.$store.commit("matchlist/update", { currentCompetition: val });
    },
    page(num) {
      const paginationData = {
        currentPage: num,
        pageSize: this.pageSize,
        total: this.totalRows,
      };
      this.$store.commit("matchlist/update", { pagination: paginationData });
    },
    pageSize(num) {
      const paginationData = {
        currentPage: this.page,
        pageSize: num,
        total: this.totalRows,
      };
      this.$store.commit("matchlist/update", { pagination: paginationData });
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
}

.data-table-cell {
  text-align: center;
}

.filter-w {
  min-width: 350px;
}

.el-pagination {
  margin-top: 1rem !important;
}

.add-match {
  float: left;
  margin-bottom: 2rem;
}

.comp-action {
  margin-bottom: 1.5rem;
  .bulk-edit {
    float: right;
    margin-left: 1rem;
  }
}
.dialog-footer {
  text-align: center;
  .el-button {
    margin: 10px;
    width: 200px;
  }
}
.active-filters {
  letter-spacing: normal;
  background-color: #fff;
  color: #000;
  padding: 0 7.5px;
  border-radius: 100%;
}
</style>
