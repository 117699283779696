<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="Team Details">
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="6" class="avatar-box entity-card">
            <img
              v-if="currentLogo"
              :src="currentLogo"
              @error="displayPlaceholder"
              alt=""
              class="avatar"
            />
            <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
            <vue-upload-component
              v-if="
                (this.type !== 'insert' && ( this.$store.getters['user/activeRole'].national_id === 31 || 
                this.$store.getters['user/activeRole'].national_id === 32))"
              ref="upload"
              :drop="true"
              v-model="files"
              :extensions="extensions"
              :accept="accept"
              :post-action="uploadURL"
              :headers="uploadHeaders"
              :data="entityData"
              :multiple="true"
              @input-file="inputFile"
              @input-filter="inputFilter"
              class="el-button el-button--default upload"
            >
             Upload
            </vue-upload-component>
          </el-col>
          <el-col :span="18">
            <el-form
              :disabled="
                (((!isTfa && team.season !== currentSeason) ||
                  ![currentSeason, currentSeason - 1].includes(team.season)) &&
                  !isSuperAdminTFA &&
                  !isSuperAdminNRL &&
                  !team.isActive) ||
                (team.expired && !isSuperAdminTFA && !isSuperAdminNRL)
              "
              label-position="top"
              :model="team"
              :rules="rules"
              ref="team"
              class="login-form"
            >
            <div class="tw-max-w-5xl" :span="24" v-if="isTfa">
              <el-form-item label="Team Registration Link">
                <el-input
                  type="text"
                  autocomplete="off"
                  v-model="teamPrivateFinderLink"
                  readonly
                  disabled=""
                >
                  <template slot="append">
                    <el-button
                      v-clipboard="teamPrivateFinderLink"
                      @click="$customSuccess('Successfully copied to clipboard')"
                    >
                      Copy
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
            </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="club" label="Club">
                    <el-select
                      :disabled="type === 'update'"
                      v-model="club"
                      placeholder="Select a club"
                      filterable
                    >
                      <el-option
                        v-if = "type !== 'update'"
                        v-for="item in clubs"
                        :label="item.name"
                        :key="item._id"
                        :value="item._id"
                      >
                    </el-option>
                    <el-option
                        v-if = "type === 'update'"
                        :label="clubs.name"
                        :key="clubs._id"
                        :value="clubs._id"
                      >
                    </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="name" label="Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="teamName"
                      placeholder="Enter a name"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="ageLvl" label="Age Group">
                    <el-select
                      v-model="team.ageLvl"
                      :disabled="clearance === roles.clubAdmin && type === 'update'"
                      placeholder="Select an age group"
                    >
                      <el-option
                        v-for="item in ageLevels.map((l) => l.name)"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="memberType" label="Member Type">
                    <el-select
                      v-model="team.memberType"
                      :disabled="clearance === roles.clubAdmin && type === 'update'"
                      placeholder="Select a member type"
                    >
                      <el-option
                        v-for="item in memberTypes.filter(
                          (p) =>
                            (p.playing && availableMemberTypes.includes(p.type)) ||
                            (p.type === 'referee' && availableMemberTypes.includes(p.type))
                        )"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.abbreviation" label="Abbreviation">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="team.meta.abbreviation"
                      placeholder="Enter an abbreviation"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contact.name" label="Contact Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="team.contact.name"
                      placeholder="Enter a contact name"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.number" label="Contact Number">
                    <vue-tel-input
                        v-model="team.contact.number"
                        :dropdownOptions="props"
                        :preferredCountries="preferredCountries"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        mode="international"
                        @input="handlePaste"
                        @validate="validateMobile"
                        :class="!isValidInternationalMobile ? 'red' : ''"
                      ></vue-tel-input>
                      <p v-if="!isValidInternationalMobile" class="red">
                        Invalid Mobile Number
                      </p>
                    
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contact.email" label="Contact Email">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="team.contact.email"
                      placeholder="Enter a contact email"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <!-- <el-col :span="12">
            <el-form-item prop="meta.website" label="Website">
              <el-input
                type="text"
                autocomplete="off"
                v-model="team.meta.website"
            placeholder="Enter a website">
              </el-input>
            </el-form-item>
          </el-col> -->
                <el-col :span="12">
                  <el-form-item prop="isActive" label="Active">
                    <el-select v-model="team.isActive">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <!-- <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item prop="meta.facebook" label="Facebook Link">
              <el-input
                type="text"
                autocomplete="off"
                v-model="team.meta.facebook"
                placeholder="">
                <template slot="prepend">https://facebook.com/</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
            </el-form>
            <action-buttons
              submit
              :cancel="cancelable"
              @submit="submitForm('team')"
              @cancel="cancelForm()"
            />
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="type == 'update' && (isAssociationAdmin || isClubAdmin)"
        label="Team Members"
      >
        <div>
          <el-col :span="24">
            <label class="el-form-item__label">{{ teamName }}</label>
          </el-col>
          <el-row type="flex" align="bottom" :gutter="10" class="search-bar">
            <el-col :span="6">
              <el-input autocomplete="off" v-model="filters[0].value" placeholder="Search...">
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <data-tables
              :filters="filters"
              :data="tableData"
              :table-props="tableProps"
              :pagination-props="paginationProps"
              :page-size="pageSize"
              @row-click="handleRowClick"
              class="data-table"
            >
              <el-table-column prop="_id" label="Id" width="100"> </el-table-column>
              <el-table-column prop="profile.firstName" label="First Name" width="130">
              </el-table-column>
              <el-table-column prop="profile.lastName" label="Last Name" width="130">
              </el-table-column>
              <el-table-column prop="profile.gender" label="Gender" width="100"> </el-table-column>
              <el-table-column :formatter="ageFormatter" prop="profile.dob" label="Age" width="120">
              </el-table-column>
              <el-table-column :formatter="dobFormatter" prop="profile.dob" label="DOB" width="150">
              </el-table-column>
              <el-table-column prop="profile.email" label="Email" width="auto"> </el-table-column>
            </data-tables>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import {
  currentSeason,
  errormsg,
  ageLevels,
  uploadURL,
  memberTypes,
  roles,
} from "../../utils/constants";

import { validateEmail, calculateAge } from "../../utils/utilities";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const dobToAge = (member) => {
  if (member) {
    const age = calculateAge(member.profile.dob, true);
    return age;
  }
  return "N/A";
};

export default {
  name: "TeamForm",
  props: {},
  components: {
    ActionButtons,
  },
  data() {
    const isClubRequired = () => {
      const activeRole = this.$store.getters["user/activeRole"];
      // [GRBAU-1990] TFA doesn't require club as compulsory
      if (activeRole.national_id === 31 && activeRole.type === roles.superAdmin) {
        return false;
      }
      return !(activeRole.national_id === 31 && activeRole.type === roles.associationAdmin);
    };

    const validateClubs = (rule, value, callback) => {
      const { team } = this;
      if (
        (!team || !team.orgtree.club || !team.orgtree.club._id || team.orgtree.club._id === "") &&
        isClubRequired()
      ) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateAgeLevels = (rule, value, callback) => {
      const { team } = this;
      if (!team || !team.ageLvl || team.ageLvl === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateMemberType = (rule, value, callback) => {
      const { team } = this;
      if (!team || !team.memberType) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateContactEmail = (rule, value, callback) => {
      const { contact } = this.team;
      if (contact.email && !validateEmail(contact.email)) {
        return callback(new Error(errormsg.email));
      }
      return callback();
    };

    return {
      teamPrivateFinderLink: "",
      preferredCountries: ["AU", "NZ", "WS", "TO", "PG", "CK", "US", "FJ", "GB", "FR"],
      isValidInternationalMobile: true,
      props: {
        showSearchBox: true,
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
      },
      type: "insert",
      teamId: null,
      nameUpdated: false,
      currentAvatar: null,
      availableMemberTypes: [],
      clubs: [],
      files: [],
      tableData: [],
      pageSize: 50,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [50],
      },
      filters: [
        {
          value: "",
          filterFn: (row, filter) =>
            Object.keys(row).some((prop) => {
              if (!filter.value || filter.value === "") return false;
              if (prop === "profile") {
                const { firstName, lastName, email } = row[prop];
                const firstNameFilter = firstName || "";
                const lastNameFilter = lastName || "";
                const emailFilter = email || "";
                const filterRegex = new RegExp(_.escapeRegExp(filter.value), "i");
                return (
                  firstNameFilter.match(filterRegex) ||
                  lastNameFilter.match(filterRegex) ||
                  emailFilter.match(filterRegex)
                );
              }
              return false;
            }),
        },
        {
          value: [],
        },
      ],
      team: {
        name: "",
        orgtree: {
          club: {
            _id: "",
            name: "",
          },
        },
        ageLvl: "",
        contact: {
          name: "",
          number: "",
          email: "",
        },
        meta: {
          abbreviation: "",
          avatar: "",
          // website: '',
          // facebook: '',
          contact:{
            countryCallingCode:"",
            countryCode:"AU",
            number:""
          }
        },
        isActive: true,
      },
      rules: {
        club: {
          type: "object",
          required: isClubRequired(),
          validator: validateClubs,
          message: errormsg.select_option,
          trigger: "blur",
        },
        name: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        ageLvl: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
          validator: validateAgeLevels,
        },
        memberType: {
          required: true,
          trigger: "blur",
          validator: validateMemberType,
        },
        "contact.number": {
          message: errormsg.phone,
          trigger: "blur",
        },
        "contact.email": {
          message: errormsg.email,
          trigger: "blur",
          validator: validateContactEmail,
        },
        isActive: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        // 'meta.website': {
        //   validator: (rule, val, callback) => {
        //     const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        //     const regex = new RegExp(expression);

        //     if (val.length && !val.match(regex)) {
        //       return callback(new Error(errormsg.invalidUrl));
        //     }
        //     return callback();
        //   },
        //   trigger: 'blur',
        // }
      },
      currentSeason,
      ageLevels,
      memberTypes,
      roles,
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, team, id } = this.$route.params;
    this.teamId = id;
    const pathSegments = this.$route.path.split('/');
    const lastValue = pathSegments[pathSegments.length - 1];
    this.type = lastValue === 'add' ? "insert" : "update"
    // Load Teams and Clubs
    if (id && id !== "" && id !== "add" && team !== undefined) {
      this.$http.get(`/nrl/api/v1/admin/teams/${id}`)
        .then((teamRes) => {
          this.team = Object.assign({}, teamRes.data.data);
          this.currentAvatar = this.team.meta ? this.team.meta.avatar : null;
          return this.$http.get(`/nrl/api/v1/admin/clubsData/${this.team.orgtree.club._id}`);
        })
          .then((clubRes) =>{
          this.clubs = clubRes.data.data || [];
          // NRL
          if ((typeof this.clubs === 'object' &&  Object.keys(this.clubs).length > 0 ) &&
            this.team.orgtree.club &&
            this.$store.getters["user/activeRole"].national_id === 32
          ) {
            const club = this.clubs._id === this.team.orgtree.club._id;
            if (club) {
              this.availableMemberTypes = this.clubs.memberTypes || [];
            }
          }
          // TFA
          if (this.$store.getters["user/activeRole"].national_id === 31) {
            this.availableMemberTypes = ["player-touch"];
            if (this.$store.getters["user/activeRole"].type <= 4)
              this.availableMemberTypes.push("referee");
            this.currentAvatar = this.team.meta ? this.team.meta.avatar : null;
          }
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);

          this.$customError();
        });
    } else {
      // Load Clubs
      this.$http
        .get("/nrl/api/v1/admin/clubs/")
        .then((response) => {
          this.clubs = response.data.data;
          // If the user is club admin, set the team's club to the only club returned from the api
          if (this.$store.getters["user/activeRole"].type === roles.clubAdmin) {
            this.team.orgtree.club = { ...this.clubs[0] };
          }
          // Include Club Data if update and passed through props
          if (team) this.team = Object.assign({}, this.team, team);
          // NRL
          if (
            this.clubs.length > 0 &&
            this.team.orgtree.club &&
            this.$store.getters["user/activeRole"].national_id === 32
          ) {
            const club = this.clubs.find((c) => c._id === this.team.orgtree.club._id);
            if (club) {
              this.availableMemberTypes = club.memberTypes || [];
            }
          }
          // TFA
          if (this.$store.getters["user/activeRole"].national_id === 31) {
            this.availableMemberTypes = ["player-touch"];
            if (this.$store.getters["user/activeRole"].type <= 4)
              this.availableMemberTypes.push("referee");
            this.currentAvatar = this.team.meta ? this.team.meta.avatar : null;
            this.teamPrivateFinderLink = this.privateFinderLink();
          }
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }

    if (this.type === "update" && (this.isClubAdmin || this.isAssociationAdmin)) {
      this.teamPrivateFinderLink = this.privateFinderLink();
      this.$http
        .get(`nrl/api/v1/admin/squad/players/${this.teamId}`)
        .then((playersData) => {
          if (!playersData.data || !playersData.data.data)
            return this.$store.commit("root/loading", false);
          const allPlayers = [
            // ...playersData.data.data.clubplayers,
            ...playersData.data.data.players,
          ];
          this.tableData = _.orderBy(
            allPlayers, // collection
            [(user) => user.profile.lastName.toLowerCase()], // iteratees
            ["profile.lastName"],
            ["asc"]
          );
          return this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    }  
  },
  methods: {
    validateMobile(data) {
      if(!data | !data.number | !data.countryCode) {
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode)
      if (parsedNumber.isValid()) {
        this.isValidInternationalMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number;
        this.team.contact.number = formattedNumber;
        const contact = {
          number : formattedNumber,
        countryCode : data.countryCode,
        countryCallingCode : data.countryCallingCode,
        }
        this.team.meta.contact = contact;
      } else {
        this.isValidInternationalMobile = false;
      }
    },

    handlePaste(number, phoneObject) {
      if(phoneObject.formatted) {
        this.team.meta.number  = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateMobile(phoneObject);
    },

    privateFinderLink() {
      const baseURL = process.env.VUE_APP_PIN_ENV === 'test'
        ? "https://test-profile.mysideline.xyz/"
        : "https://profile.mysideline.com.au/";

      const { national_id } = this.$store.getters["user/activeRole"];
      const isTfa = national_id === 31;

      const baseLink = new URL('/register/clubsearch/', baseURL);
      baseLink.searchParams.set('criteria', this.teamId);
      baseLink.searchParams.set('source', isTfa ? 'touch-football' : 'rugby-league');
      baseLink.searchParams.set('entityType', 'team');
      baseLink.searchParams.set('isEntityIdSearch', 'true');
      baseLink.searchParams.set('entity', 'true');

      return baseLink.toString();
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          this.team.meta.avatar = newFile.response.url;
          this.currentAvatar = newFile.response.url;
          if (this.type === "update") {
            const { avatar } = this.team.meta;
            const { _id } = this.team;
            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };
            const url = `/nrl/api/v1/admin/teams/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!this.isValidInternationalMobile){
            this.team.contact.number = "";
            const contact = {
              number : "",
              countryCode : "",
              countryCallingCode : "",
            }
            this.team.meta.contact = contact;

          }
          const currentTeam = this.team;
          if (this.type === "update") {
            this.$store.commit("root/loading", true);
            const update = {
              ...currentTeam,
              orgtree: currentTeam.orgtree,
            };
            if (this.nameUpdated) update.name = currentTeam.name;
            const url = `/nrl/api/v1/admin/teams/${currentTeam._id}`;
            this.$http
              .put(url, update)
              .then((res) => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                // update data member in store
                this.$store.commit("entities/updateDataMember", { entity: "teams", data: update });

                if (res.data.message) {
                  this.$customError(res.data.message);
                } else {
                  this.$customSuccess();
                }
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const insert = currentTeam;

            // TFA association admin
            const activeRole = this.$store.getters["user/activeRole"];
            if (activeRole.national_id === 31 && activeRole.type === roles.associationAdmin) {
              // Remove club from orgtree if not exist
              if (insert.orgtree.club && insert.orgtree.club._id === "") delete insert.orgtree.club;
            }

            const url = "/nrl/api/v1/admin/teams/";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
                this.$router.push({
                  name: "teams",
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({
        name: "teams",
      });
    },
    handleRowClick(row) {
      this.$router.push({
        name: "team.update",
        params: {
          type: "update",
          id: row._id,
          teamId: this.teamId,
        },
      });
    },
    ageFormatter(row) {
      return row && row.profile && row.profile.dob ? dobToAge(row) : "N/A";
    },
    dobFormatter(row) {
      // row.profile.dob format is YYYY-MM-DD
      const splits = row.profile.dob.split("-"); // [YYYY, MM, DD]
      if (splits.length !== 3) return "";
      return `${splits[2]}-${splits[1]}-${splits[0]}`; // DD-MM-YYYY
    },
    memberTypeFormatter(row) {
      // get member type name
      return (
        row.memberTypes &&
        (row.memberTypes
          .map((mt) => {
            const memberType = _.find(memberTypes, (m) => m.type === mt);
            return (memberType && memberType.name) || "";
          })
          .join(", ") ||
          "N/A")
      );
    },
    pendingFormatter(row) {
      return row && row.verification && row.verification.pending ? "Yes" : "No";
    },
  },
  computed: {
    isSuperAdminNRL() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.superAdmin && national_id === 32;
    },
    isSuperAdminTFA() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.superAdmin && national_id === 31;
    },
    cancelable() {
      return this.$route.matched.filter((route) => route.path === "/my").length === 0;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    entityData() {
      return {
        entity_type: "team",
        entity_id: this.team._id,
      };
    },
    uploadURL() {
      return uploadURL;
    },
    currentLogo() {
      return this.currentAvatar;
    },
    teamName: {
      get() {
        return this.team.name;
      },
      set(value) {
        this.nameUpdated = true;
        this.team.name = value;
      },
    },
    club: {
      get() {
        return this.team.orgtree.club && this.team.orgtree.club._id;
      },
      set(clubid) {
        const foundClub = this.clubs.find((obj) => obj._id === clubid);
        if (foundClub) {
          const { orgtree, _id, name, memberTypes: clubMemberTypes } = foundClub;
          orgtree.club = { _id, name };
          this.team.orgtree = orgtree;
          this.availableMemberTypes = clubMemberTypes || [];
          if (orgtree.national._id === 31) this.availableMemberTypes.push("referee");
        }
      },
    },
    isAssociationAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.associationAdmin;
    },
    isClubAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.clubAdmin;
    },
    isTfa() {
      const { national_id } = this.$store.getters["user/activeRole"];
      return national_id === 31;
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.entity-card {
  height: 36rem;
}

.avatar-box {
  overflow: hidden;
  .avatar {
    margin: 0 auto;
    height: 12.5rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }
  .upload {
    margin: 0 auto;
    display: block;
    max-width: 70%;
  }
}

.operation {
  el-button {
    width: 100%;
    height: 100%;
  }
  .red{
    color:red
  }
}
</style>
